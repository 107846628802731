<template>
  <div>
    <div v-show="state === states.READY">
      <BasePageHeader v-if="state === states.READY">
        <div>
          <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
          <BasePageHeading>Start Subscription</BasePageHeading>
        </div>
      </BasePageHeader>
      <BaseWrapper>
        <div class="text-center">
          <h1 class="font-bold text-4xl my-6">
            Thank you
          </h1>
          <p class="my-2">
            We’ve taken your first payment and you’ve started your subscription
          </p>
          <p class="text-sm my-2">
            You’ll be sent an email confirming your payment
          </p>
          <div>
            <BaseButton
              variant="green"
              class="my-12"
              :to="{ name: 'client-assessments-list' }"
            >
              Return to your Assessments
            </BaseButton>
          </div>
        </div>
      </BaseWrapper>
    </div>
    <div v-if="state === states.LOADING">
      <BasePageHeader>
        <div>
          <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
          <BasePageHeading>Start Subscription</BasePageHeading>
        </div>
      </BasePageHeader>
      <Loader />
      <p
        v-if="loadingMessage"
        class="text-center mt-4"
      >
        {{ loadingMessage }}
      </p>
    </div>
    <div v-else-if="state === states.ERROR">
      <BaseErrorBlock />
    </div>
  </div>
</template>

<script>
import Loader from '@components/Loader'

import states from '@api/states'
import { mapGetters } from 'vuex'
import checkoutApi from '@api/checkout'

export default {
  components: {
    Loader
  },

  data() {
    return {
      states,

      error: null,
      errorMessage: null,
      loadingMessage: null,

      success: false
    }
  },

  computed: {
    ...mapGetters({
      organisationName: 'organisations/name'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      // Normally with breadcrumbs() we only use this when the page has
      // fully loaded, but on the checkout it makes a nice consistency if
      // the breadcrumb is always there, so we only require that the
      // organisationName is there for use
      if (!this.organisationName) {
        return []
      }

      return [
        {
          name: this.organisationName
        },
        {
          name: 'Plans',
          to: 'settings-payg-plan'
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (!this.success) {
        return states.LOADING
      }

      return states.READY
    },

    /**
     * @return {string}
     */
    currency() {
      return this.$route.params.currency.toUpperCase()
    },

    /**
     * @return {string}
     */
    planSlug() {
      return this.$route.params.plan
    },

    /**
     * @return {string}
     */
    redirectStatus() {
      return this.$route.query.redirect_status
    },

    /**
     * @return {string}
     */
    paymentIntent() {
      return this.$route.query.payment_intent
    },

    /**
     * @return {string}
     */
    paymentIntentClientSecret() {
      return this.$route.query.payment_intent_client_secret
    }
  },

  mounted() {
    this.finishSubscription()
  },

  methods: {
    /**
     * Setup the customer and PaymentIntent
     */
    finishSubscription() {
      this.loadingMessage = 'Finishing processing your payment'

      checkoutApi.finishSubscriptions({
        currency: this.currency,
        planSlug: this.planSlug,
        paymentIntent: this.paymentIntent,
        paymentIntentClientSecret: this.paymentIntentClientSecret
      })
        .then(response => {
          this.loadingMessage = 'Updating your account'

          if (response.data.success) {
            return this.$store.dispatch('subscriptions/getSubscriptions')
              .then(() => {
                this.loadingMessage = null
                this.success = true
              })
              .catch(error => {
                this.loadingMessage = null
                this.error = error
                throw error
              })
          }

          const error = new Error('Did not get success message')
          this.error = error
          throw error
        })
        .catch(error => {
          this.loadingMessage = null
          this.error = error
          throw error
        })
    }
  }
}
</script>
